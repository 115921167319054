<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1400px"
      scrollable
      @keydown="executeCloseModalPurchaseOrders"
      @click:outside="executeCloseModalPurchaseOrders"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h4 class="mb-5">
            Ordenes de Compra
          </h4>
          <h5 v-if="currentMaterial">
            COD Material:
            <b>
              {{ currentMaterial.material.code }}
            </b>
            | Descripción:
            <b>
              {{ currentMaterial.material.name }}
            </b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0 px-0">
            <v-data-table
              v-if="currentMaterial"
              class="px-2 elevation-4 pt-2 "
              :headers="tableHeaders"
              :items="currentMaterial.purchase_order_items"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando ordenes"
              item-key="id"
              no-data-text="Sin ordenes de compra"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-center">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.purchase_order.sap_code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.user="{ item }">
                <div class="d-flex justify-content-center">
                  {{
                    item.purchase_order.document_owner
                      ? item.purchase_order.document_owner.email
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:item.quantity="{ item }">
                <p class="mt-4" style="font-size: 11px">
                  {{ item.quantity }}
                </p>
              </template>
              <template v-slot:item.price="{ item }">
                <p class="ml-5 mt-4" style="font-size: 11px">
                  {{
                    item.price.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })
                  }}
                </p>
              </template>
              <template v-slot:item.total="{ item }">
                <p class="ml-5 mt-4" style="font-size: 11px">
                  {{
                    item.purchase_order.total.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })
                  }}
                </p>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span class="mt-2" style="font-size: 12px;">
                  {{
                    item.purchase_order.created_at
                      ? moment(item.purchase_order.created_at).format(
                          "DD/MM/YYYY HH:mm [hrs]"
                        )
                      : "Sin definir"
                  }}
                </span>
              </template>
              <template v-slot:item.delivery_date="{ item }">
                <span class="mt-2" style="font-size: 12px;">
                  {{
                    item.delivery_date
                      ? moment(item.delivery_date).format(
                          "DD/MM/YYYY HH:mm [hrs]"
                        )
                      : "Sin definir"
                  }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-items-center justify-content-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!item.inventory_entries.length"
                        @click="openModalInventoryEntries(item)"
                        icon
                        class="mx-2"
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                      >
                        <v-icon small>
                          mdi-clipboard-check
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Entradas de Mercancia asociadas</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalPurchaseOrders"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal-inventory-entries
      :modalAction="dialogInventoryEntries"
      :closeModalInventoryEntries="closeModalInventoryEntries"
      :currentPurchaseOrder="currentPurchaseOrder"
    >
    </modal-inventory-entries>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";
import ModalInventoryEntries from "./ModalInventoryEntries.vue";

export default {
  components: { ModalInventoryEntries },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalPurchaseOrders: {
      type: Function,
      default: () => {},
    },
    currentMaterial: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      dialogInventoryEntries: false,
      currentPurchaseOrder: null,
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Código SAP",
          align: "center",
          sortable: false,
          value: "code",
          width: "15%",
        },
        {
          text: "Usuario",
          align: "center",
          sortable: false,
          value: "user",
          width: "15%",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "5%",
        },
        {
          text: "Precio",
          align: "center",
          sortable: false,
          value: "price",
          width: "10%",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: false,
          width: "10%",
          value: "created_at",
        },
        {
          text: "ETA",
          align: "center",
          sortable: false,
          width: "15%",
          value: "delivery_date",
        },
      ],
    };
  },
  methods: {
    openModalInventoryEntries(item) {
      this.currentPurchaseOrder = item;
      this.dialogInventoryEntries = true;
    },
    closeModalInventoryEntries() {
      this.currentPurchaseOrder = null;
      this.dialogInventoryEntries = false;
    },
    executeCloseModalPurchaseOrders($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalPurchaseOrders($event);
      }
    },
  },
};
</script>

<style></style>
