<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1400px"
      scrollable
      @keydown="executeCloseModalInventoryEntries"
      @click:outside="executeCloseModalInventoryEntries"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h4 class="mb-5">
            Entradas de Mercancia
          </h4>
          <h5 v-if="currentPurchaseOrder">
            COD Material:
            <b>
              {{ currentPurchaseOrder.material.code }}
            </b>
            | Descripción:
            <b>
              {{ currentPurchaseOrder.material.name }}
            </b>
            | Código Orden:
            <b>
              {{ currentPurchaseOrder.purchase_order.sap_code }}
            </b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0 px-0">
            <v-data-table
              v-if="currentPurchaseOrder"
              class="px-2 elevation-4 pt-2 "
              :headers="tableHeaders"
              :items="currentPurchaseOrder.inventory_entries"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando entradas"
              item-key="id"
              no-data-text="Sin entradas de mercancia"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-center">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.inventory_entry.sap_code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.user="{ item }">
                <div class="d-flex justify-content-center">
                  {{ item.inventory_entry.document_owner.email }}
                </div>
              </template>
              <template v-slot:item.quantity="{ item }">
                <p class="mt-4" style="font-size: 11px">
                  {{ item.quantity }}
                </p>
              </template>
              <template v-slot:item.price="{ item }">
                <p class="ml-5 mt-4" style="font-size: 11px">
                  {{
                    item.price.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })
                  }}
                </p>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span class="mt-2" style="font-size: 12px;">
                  {{
                    item.inventory_entry.created_at
                      ? moment(item.inventory_entry.created_at).format(
                          "DD/MM/YYYY HH:mm [hrs]"
                        )
                      : "Sin definir"
                  }}
                </span>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalInventoryEntries"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalInventoryEntries: {
      type: Function,
      default: () => {},
    },
    currentPurchaseOrder: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      tableHeaders: [
        {
          text: "Código SAP",
          align: "center",
          sortable: false,
          value: "code",
          width: "15%",
        },
        {
          text: "Usuario",
          align: "center",
          sortable: false,
          value: "user",
          width: "15%",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "5%",
        },
        {
          text: "Precio",
          align: "center",
          sortable: false,
          value: "price",
          width: "10%",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: false,
          width: "10%",
          value: "created_at",
        },
      ],
    };
  },
  methods: {
    executeCloseModalInventoryEntries($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalInventoryEntries($event);
      }
    },
  },
};
</script>

<style></style>
