var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1400px","scrollable":""},on:{"keydown":_vm.executeCloseModalInventoryEntries,"click:outside":_vm.executeCloseModalInventoryEntries},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h4',{staticClass:"mb-5"},[_vm._v(" Entradas de Mercancia ")]),(_vm.currentPurchaseOrder)?_c('h5',[_vm._v(" COD Material: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentPurchaseOrder.material.code)+" ")]),_vm._v(" | Descripción: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentPurchaseOrder.material.name)+" ")]),_vm._v(" | Código Orden: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentPurchaseOrder.purchase_order.sap_code)+" ")])]):_vm._e()]),_c('hr'),_c('v-card-text',[_c('v-container',{staticClass:"pt-0 px-0"},[(_vm.currentPurchaseOrder)?_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2 ",attrs:{"headers":_vm.tableHeaders,"items":_vm.currentPurchaseOrder.inventory_entries,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando entradas","item-key":"id","no-data-text":"Sin entradas de mercancia"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.inventory_entry.sap_code)+" ")])],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(item.inventory_entry.document_owner.email)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ml-5 mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.price.toLocaleString("es-CL", { style: "currency", currency: "CLP", }))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.inventory_entry.created_at ? _vm.moment(item.inventory_entry.created_at).format( "DD/MM/YYYY HH:mm [hrs]" ) : "Sin definir")+" ")])]}}],null,false,1467624319)}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalInventoryEntries}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }