var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1350px","scrollable":""},on:{"keydown":_vm.executeCloseModalShowRequestMaterial,"click:outside":_vm.executeCloseModalShowRequestMaterial},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[(_vm.currentRequestMaterials)?_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" COD "+_vm._s(_vm.currentRequestMaterials.project ? _vm.currentRequestMaterials.project.code : _vm.currentRequestMaterials.cost_center.sap_code)+" | Tipo de solicitud: "+_vm._s(_vm.currentRequestMaterials.is_service ? _vm.currentRequestMaterials.material_request_type.name == _vm.REQUEST_MATERIAL ? "Solicitud de Servicios" : "Solicitud de Servicios Urgente" : _vm.currentRequestMaterials.material_request_type.name)+"/ "),(_vm.currentRequestMaterials.project)?_c('b',[_vm._v(" "+_vm._s(("ETAPA DEL PROYECTO: " + (_vm.currentRequestMaterials.stage ? _vm.currentRequestMaterials.stage.name : "Sin etapa")))+" ")]):_vm._e()])]),_c('hr'),_c('v-card-text',[_c('v-container',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('section',{staticClass:"w-25"},[_c('v-text-field',{staticClass:"px-2",attrs:{"clearable":"","placeholder":"Buscar:"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)]),_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredMaterials,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)]}},{key:"item.type_request",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.typesRequests[item.type_request].name)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.unit)+" ")])]}},{key:"item.sap_code",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sap_code)+" ")])]}},{key:"item.eta",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.typesRequests[item.type_request].eta ? _vm.getEtaFromMaterialPurchaseRequest(item) ? _vm.moment( _vm.getEtaFromMaterialPurchaseRequest(item) ).format("DD/MM/YYYY") : "-" : "-")+" ")])]}},{key:"item.status_material",fn:function(ref){
var item = ref.item;
return [(_vm.typesRequests[item.type_request].eta)?_c('v-chip',{staticClass:"text-white",attrs:{"label":"","small":"","color":_vm.getMappedStatusPurchaseRequest(
                    _vm.getStatusMaterialFromMaterialPurchaseRequest(item)
                  ).color}},[_vm._v(" "+_vm._s(_vm.getMappedStatusPurchaseRequest( _vm.getStatusMaterialFromMaterialPurchaseRequest(item) ).name)+" ")]):_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(_vm.typesRequests[item.type_request].eta)?_c('v-chip',{staticClass:"text-white",attrs:{"label":"","small":"","color":_vm.getColorFromStatusPlataform(
                    _vm.getStatusRequestFromPurchaseRequest(item)
                  )}},[_vm._v(" "+_vm._s(_vm.getStatusRequestFromPurchaseRequest(item))+" ")]):_c('v-chip',{staticClass:"text-white",attrs:{"label":"","small":"","color":_vm.getMappedStatusRequestTransfer(
                    _vm.getStatusRequestFromTransferRequest(item)
                  ).color}},[_vm._v(" "+_vm._s(_vm.getMappedStatusRequestTransfer( _vm.getStatusRequestFromTransferRequest(item) ).name)+" ")])]}},{key:"item.quantity",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"text-right mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.typesRequests[item.type_request].eta)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"disabled":!_vm.getPurchaseOrdersFromMaterialPurchaseRequest(item)
                          .length,"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openModalPurchaseOrders(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-clipboard-text ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Ordenes de Compra asociadas")])]):_c('span',[_vm._v(" - ")])],1)]}}],null,false,2116131345)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalShowRequestMaterial}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1):_vm._e()],1),_c('modal-purchase-orders',{attrs:{"modalAction":_vm.dialogPurchaseOrders,"closeModalPurchaseOrders":_vm.closeModalPurchaseOrders,"currentMaterial":_vm.currentMaterial}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }