<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1350px"
      scrollable
      @keydown="executeCloseModalShowRequestMaterial"
      @click:outside="executeCloseModalShowRequestMaterial"
    >
      <v-card v-if="currentRequestMaterials">
        <div class="p-4 pb-0">
          <h5>
            COD
            {{
              currentRequestMaterials.project
                ? currentRequestMaterials.project.code
                : currentRequestMaterials.cost_center.sap_code
            }}
            | Tipo de solicitud:
            {{
              currentRequestMaterials.is_service
                ? currentRequestMaterials.material_request_type.name ==
                  REQUEST_MATERIAL
                  ? "Solicitud de Servicios"
                  : "Solicitud de Servicios Urgente"
                : currentRequestMaterials.material_request_type.name
            }}/
            <b v-if="currentRequestMaterials.project">
              {{
                `ETAPA DEL PROYECTO: ${
                  currentRequestMaterials.stage
                    ? currentRequestMaterials.stage.name
                    : "Sin etapa"
                }`
              }}
            </b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0">
            <div class="d-flex justify-content-end">
              <section class="w-25">
                <v-text-field
                  v-model="filters.filter"
                  class="px-2"
                  clearable
                  placeholder="Buscar:"
                ></v-text-field>
              </section>
            </div>
            <v-data-table
              class="px-2 elevation-4 pt-2"
              :headers="tableHeaders"
              :items="filteredMaterials"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-start">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.type_request="{ item }">
                <span style="font-size: 11px">
                  {{ typesRequests[item.type_request].name }}
                </span>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.unit="{ item }">
                <p class="text-center my-0" style="font-size: 11px">
                  {{ item.unit }}
                </p>
              </template>
              <template v-slot:item.sap_code="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.sap_code }}
                </p>
              </template>
              <template v-slot:item.eta="{ item }">
                <p class="text-center my-0" style="font-size: 11px">
                  {{
                    typesRequests[item.type_request].eta
                      ? getEtaFromMaterialPurchaseRequest(item)
                        ? moment(
                            getEtaFromMaterialPurchaseRequest(item)
                          ).format("DD/MM/YYYY")
                        : "-"
                      : "-"
                  }}
                </p>
              </template>
              <template v-slot:item.status_material="{ item }">
                <v-chip
                  v-if="typesRequests[item.type_request].eta"
                  label
                  small
                  :color="
                    getMappedStatusPurchaseRequest(
                      getStatusMaterialFromMaterialPurchaseRequest(item)
                    ).color
                  "
                  class="text-white"
                >
                  {{
                    getMappedStatusPurchaseRequest(
                      getStatusMaterialFromMaterialPurchaseRequest(item)
                    ).name
                  }}
                </v-chip>
                <p v-else class="text-center my-0" style="font-size: 11px">-</p>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  v-if="typesRequests[item.type_request].eta"
                  label
                  small
                  :color="
                    getColorFromStatusPlataform(
                      getStatusRequestFromPurchaseRequest(item)
                    )
                  "
                  class="text-white"
                >
                  {{ getStatusRequestFromPurchaseRequest(item) }}
                </v-chip>
                <v-chip
                  v-else
                  label
                  small
                  :color="
                    getMappedStatusRequestTransfer(
                      getStatusRequestFromTransferRequest(item)
                    ).color
                  "
                  class="text-white"
                >
                  {{
                    getMappedStatusRequestTransfer(
                      getStatusRequestFromTransferRequest(item)
                    ).name
                  }}
                </v-chip>
              </template>
              <template v-slot:item.quantity="{ item }">
                <p class="text-right mt-4" style="font-size: 11px">
                  {{ item.quantity }}
                </p>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-items-center justify-content-center">
                  <v-tooltip top v-if="typesRequests[item.type_request].eta">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="
                          !getPurchaseOrdersFromMaterialPurchaseRequest(item)
                            .length
                        "
                        @click="openModalPurchaseOrders(item)"
                        icon
                        class="mx-2"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                      >
                        <v-icon small> mdi-clipboard-text </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Ordenes de Compra asociadas</span>
                  </v-tooltip>
                  <span v-else> - </span>
                </div>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalShowRequestMaterial"
          >
            <small> Cerrar </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal-purchase-orders
      :modalAction="dialogPurchaseOrders"
      :closeModalPurchaseOrders="closeModalPurchaseOrders"
      :currentMaterial="currentMaterial"
    ></modal-purchase-orders>
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { TYPES_REQUESTS_SAP } from "@/constants/requestMaterial";
import statusAndEtaRequestsMixin from "@/mixins/statusAndEtaRequestsMixin";
import { REQUEST_MATERIAL } from "@/constants/requestMaterial";
import ModalPurchaseOrders from "../../supplying/purchaseModule/purchaseRequests/ModalPurchaseOrders.vue";
export default {
  components: { ModalPurchaseOrders },
  mixins: [statusAndEtaRequestsMixin],
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalShowRequestMaterials: {
      type: Function,
      default: () => {},
    },
    currentRequestMaterials: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      REQUEST_MATERIAL,
      currentMaterials: [],
      filters: {
        filter: "",
      },
      //Modal Purchase Orders
      dialogPurchaseOrders: false,
      currentMaterial: null,

      typesRequests: TYPES_REQUESTS_SAP,
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: `Código ${
            this.currentRequestMaterials &&
            this.currentRequestMaterials.is_service
              ? "Servicio"
              : "Material"
          }`,
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          class: "text-left",
          sortable: false,
          value: "name",
        },
        {
          text: "Cantidad Solicitada",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "unit",
        },
        {
          text: "Código SAP",
          align: "start",
          width: "10%",
          sortable: false,
          value: "sap_code",
        },
        {
          text: "ETA",
          align: "start",
          sortable: false,
          value: "eta",
        },
        {
          text: "Estatus Solicitud",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Estatus Material",
          align: "start",
          sortable: false,
          value: "status_material",
        },
        {
          text: "Tipo de Solicitud",
          align: "center",
          sortable: false,
          width: "15%",
          value: "type_request",
        },
      ],
    };
  },
  methods: {
    executeCloseModalShowRequestMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentMaterials = [];
        this.closeModalShowRequestMaterials($event);
      }
    },
    setMaterialsCurrentRequest(requests) {
      requests.forEach((request) => {
        this.currentMaterials.push(
          ...request.sap_request_items.map((item) => {
            return {
              ...item.material,
              quantity: item.quantity,
              unit: item.material.measurement_unit.name,
              sap_code: request.sap_number,
              type_request: request.sap_request_type.name,
            };
          })
        );
      });
    },
    openModalPurchaseOrders(material) {
      this.currentMaterial = {
        material: { ...material },
        purchase_order_items:
          this.getPurchaseOrdersFromMaterialPurchaseRequest(material),
      };
      this.dialogPurchaseOrders = true;
    },
    closeModalPurchaseOrders() {
      this.dialogPurchaseOrders = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      warehouses: "requestMaterialsSap/warehouses",
    }),
    filteredMaterials() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.currentMaterials.filter((approveRequest) => {
          return (
            approveRequest.code.toLowerCase().includes(filter) ||
            approveRequest.sap_code.toLowerCase().includes(filter) ||
            approveRequest.name.toLowerCase().includes(filter) ||
            approveRequest.unit.toLowerCase().includes(filter)
          );
        });
      }
      return this.currentMaterials;
    },
  },
  watch: {
    currentRequestMaterials(newRequest) {
      if (newRequest) {
        this.setMaterialsCurrentRequest(newRequest.sap_material_request);
        this.setSapCodesAndGetStatusRequests(newRequest.sap_material_request);
      }
    },
  },
};
</script>

<style></style>
