<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      scrollable
      @keydown="executeCloseModalRecepcionRequestMaterial"
      @click:outside="executeCloseModalRecepcionRequestMaterial"
    >
      <v-card v-if="currentRequestMaterials">
        <div class="p-4 pb-0">
          <h5>
            Solicitud de
            {{
              currentRequestMaterials.is_service ? "Servicios" : "Materiales"
            }}
          </h5>
          <h6 class="mt-5">
            COD
            {{
              currentRequestMaterials.project
                ? currentRequestMaterials.project.code
                : currentRequestMaterials.cost_center.sap_code
            }}
            | Tipo de solicitud:
            {{
              currentRequestMaterials.is_service
                ? currentRequestMaterials.material_request_type.name ==
                  REQUEST_MATERIAL
                  ? "Solicitud de Servicios"
                  : "Solicitud de Servicios Urgente"
                : currentRequestMaterials.material_request_type.name
            }}
            /
            <b v-if="currentRequestMaterials.project">
              {{
                `ETAPA DEL PROYECTO: ${
                  currentRequestMaterials.stage
                    ? currentRequestMaterials.stage.name
                    : "Sin etapa"
                }`
              }}
            </b>
          </h6>
        </div>
        <hr />
        <v-card-text>
          <div class="px-3">
            <div class="d-flex justify-content-end">
              <section class="w-25">
                <v-text-field
                  v-model="filters.filter"
                  class="px-2"
                  clearable
                  placeholder="Buscar:"
                ></v-text-field>
              </section>
            </div>
            <v-data-table
              class="px-2 elevation-4 pt-2"
              :headers="tableHeaders"
              :items="filteredMaterials"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-start">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.requested="{ item }">
                <p class="text-right my-0">
                  {{ item.requested }}
                </p>
              </template>
              <template v-slot:item.received="{ item }">
                <v-btn
                  color="primary darken-1"
                  outlined
                  small
                  v-if="item.received == null"
                  @click="
                    openModalUpdateReceptionMaterial(
                      item,
                      actionsToReceptionMaterial.received
                    )
                  "
                >
                  <small>
                    Completar
                  </small>
                </v-btn>
                <div
                  v-else
                  class="d-flex align-items-center justify-content-end"
                >
                  <p class="my-0">
                    {{ item.received }}
                  </p>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.consumed == null"
                        icon
                        @click="
                          openModalUpdateReceptionMaterial(
                            item,
                            actionsToReceptionMaterial.received,
                            Number(item.received)
                          )
                        "
                      >
                        <v-icon small color="primary">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.consumed="{ item }">
                <v-btn
                  color="primary darken-1"
                  outlined
                  small
                  v-if="item.received && item.consumed == null"
                  @click="
                    openModalUpdateReceptionMaterial(
                      item,
                      actionsToReceptionMaterial.consumed
                    )
                  "
                >
                  <small>
                    Completar
                  </small>
                </v-btn>
                <div
                  v-else-if="item.consumed"
                  class="d-flex align-items-center justify-content-end"
                >
                  <p class="my-0">
                    {{ item.consumed }}
                  </p>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="mr-1"
                        @click="
                          openModalUpdateReceptionMaterial(
                            item,
                            actionsToReceptionMaterial.consumed,
                            Number(item.consumed)
                          )
                        "
                      >
                        <v-icon small color="primary">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.returned="{ item }">
                <v-btn
                  color="primary darken-1"
                  outlined
                  small
                  v-if="item.consumed !== null && item.returned == null"
                  @click="
                    openModalUpdateReceptionMaterial(
                      item,
                      actionsToReceptionMaterial.returned
                    )
                  "
                >
                  <small>
                    Completar
                  </small>
                </v-btn>
                <span v-else>
                  {{ item.returned }}
                </span>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalRecepcionRequestMaterial"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <update-reception-material
      ref="updateReceptionMaterial"
      :modalAction="dialogUpdateReceptionMaterial"
      :closeModalUpdateReceptionMaterial="closeModalUpdateReceptionMaterial"
      :currentMaterial="currentMaterialToUpdate"
      :action="currentAction"
      @actionUpdated="updateReceptionCurrentMaterial"
      @commentUpdated="updateCommentCurrentMaterial"
    >
    </update-reception-material>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import UpdateReceptionMaterial from "./UpdateReceptionMaterial.vue";
import {
  ACTIONS_TO_RECEPTION_MATERIAL,
  REQUEST_MATERIAL,
} from "@/constants/requestMaterial";

export default {
  components: { UpdateReceptionMaterial },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalReceptionRequestMaterials: {
      type: Function,
      default: () => {},
    },
    currentRequestMaterials: {
      default: null,
    },
  },
  data() {
    return {
      REQUEST_MATERIAL,
      actionsToReceptionMaterial: ACTIONS_TO_RECEPTION_MATERIAL,
      dialogUpdateReceptionMaterial: false,
      currentMaterials: [],
      currentMaterialToUpdate: null,
      currentAction: null,
      filters: {
        filter: "",
      },
      typesRequests: {
        InventoryTransferRequest: "Transferencia de inventario",
        PurchaseRequest: "Orden de compra",
      },
      tableHeaders: [
        {
          text: `Código ${
            this.currentRequestMaterials &&
            this.currentRequestMaterials.is_service
              ? "Servicio"
              : "Material"
          }`,
          align: "start",
          width: "5%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          width: "50%",
          sortable: false,
          value: "name",
        },
        {
          text: "Solicitados",
          align: "center",
          sortable: false,
          value: "requested",
        },
        {
          text: "Recepcionados",
          align: "center",
          sortable: false,
          value: "received",
        },
        {
          text: "Consumidos",
          align: "center",
          sortable: false,
          width: "14%",
          value: "consumed",
        },
        {
          text: "Devueltos",
          align: "center",
          sortable: false,
          width: "3%",
          value: "returned",
        },
        {
          text: "Comentario",
          align: "center",
          sortable: false,
          width: "10%",
          value: "comment",
        },
      ],
    };
  },
  methods: {
    ...mapActions({}),
    openModalUpdateReceptionMaterial(material, action, quantity = null) {
      this.currentMaterialToUpdate = material;
      this.currentAction = action;
      if (quantity) {
        this.$refs.updateReceptionMaterial.form.quantity = quantity;
      }
      this.dialogUpdateReceptionMaterial = true;
    },
    closeModalUpdateReceptionMaterial() {
      this.currentMaterialToUpdate = null;
      this.currentAction = null;
      this.dialogUpdateReceptionMaterial = false;
    },
    executeCloseModalRecepcionRequestMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentMaterials = [];
        this.closeModalReceptionRequestMaterials($event);
      }
    },
    setMaterialsCurrentRequest(request) {
      this.currentMaterials.push(
        ...request.material_request_items.map((item) => {
          return {
            ...item.material,
            ...item,
          };
        })
      );
    },
    updateReceptionCurrentMaterial({ id, action, quantity }) {
      this.$emit("receptionUpdated");
      this.currentMaterials.forEach((item) => {
        if (item.id == id) {
          switch (action) {
            case this.actionsToReceptionMaterial.received:
              item.received = quantity;
              break;
            case this.actionsToReceptionMaterial.consumed:
              item.consumed = quantity;
              break;
            case this.actionsToReceptionMaterial.returned:
              item.returned = quantity;
              break;
            default:
              break;
          }
        }
      });
    },
    updateCommentCurrentMaterial({ id, comment }) {
      this.currentMaterials.forEach((item) => {
        if (item.id == id) {
          item.comment = comment;
        }
      });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      warehouses: "requestMaterialsSap/warehouses",
    }),
    filteredMaterials() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.currentMaterials.filter((approveRequest) => {
          return (
            approveRequest.code.toLowerCase().includes(filter) ||
            approveRequest.name.toLowerCase().includes(filter)
          );
        });
      }
      return this.currentMaterials;
    },
  },
  watch: {
    currentRequestMaterials(newRequest) {
      if (newRequest) {
        this.setMaterialsCurrentRequest(newRequest);
      }
    },
  },
};
</script>

<style></style>
